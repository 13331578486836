import React from "react"
import BackgroundImage from "gatsby-background-image"

const PageBanner = ({ image, heading }) => {
  return (
    <BackgroundImage
      tag="section"
      fluid={image.localFile.childImageSharp.fluid}
      className="w-100 vh-100 flex items-center justify-center mb4"
    >
      <h1 className="ttu f2 f1-l b tracked measure center tc">
        {heading.text}
      </h1>
    </BackgroundImage>
  )
}

export default PageBanner
