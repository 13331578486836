import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

const StandardBlock = ({ heading, text, image, reverse_layout, link }) => {
  const reverse = reverse_layout === "true"
  return (
    <section
      className={`mv3 mv6-l standard-block ${reverse && "reverse-layout"}`}
    >
      <div className="ph4 pb6-l relative img-wrap">
        <Img
          fluid={image.localFile.childImageSharp.fluid}
          className="relative z-1"
        />
        <div className="absolute bottom-0 left-0 h-50 w-100 bg-white db dn-l" />
      </div>
      <div className="pt6-l text-outer flex-l">
        <div className="bg-white ph4 ph5-l pt4 pb5 pv6-l w-100 text-wrap flex-l flex-column-l justify-center tl">
          <h2 className="f3 f1-l ttu tracked b mb3">{heading.text}</h2>
          <div
            dangerouslySetInnerHTML={{ __html: text.html }}
            className="lh-copy f6 measure-wide"
          />
          {link && (
            <Link
              to={`/${link.uid}`}
              className="link black ttu b tracked mt2 f5"
            >
              Read More
            </Link>
          )}
        </div>
      </div>
    </section>
  )
}

export default StandardBlock
