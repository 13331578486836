import React from "react"
import PropTypes from "prop-types"

import {
  PageBanner,
  TwoColumnText,
  StandardBlock,
  FeatureBlock,
  MembershipTeaser,
  CenteredImage,
  SingleColText,
  CenteredText,
  WineBlock,
  CenteredQuote,
  EditorialOne,
  EditorialTwo,
  MembershipTable,
  ContactBlock,
  Spacer,
} from "../slices"

const SliceZone = ({ slices }) => {
  const slice = slices.map(s => {
    switch (s.slice_type) {
      case "page_banner":
        return <PageBanner key={s.id} {...s.primary} />
      case "single_column_text":
        return <SingleColText key={s.id} {...s.primary} />
      case "two_column_text":
        return <TwoColumnText key={s.id} {...s.primary} />
      case "centered_text":
        return <CenteredText key={s.id} {...s.primary} />
      case "standard_content":
        return <StandardBlock key={s.id} {...s.primary} />
      case "feature_content":
        return <FeatureBlock key={s.id} {...s.primary} />
      case "membership_teaser":
        return <MembershipTeaser key={s.id} {...s.primary} />
      case "centered_image":
        return <CenteredImage key={s.id} {...s.primary} />
      case "wine_block":
        return <WineBlock key={s.id} {...s.primary} />
      case "centered_quote":
        return <CenteredQuote key={s.id} {...s.primary} />
      case "editorial_one":
        return <EditorialOne key={s.id} {...s.primary} />
      case "editorial_two":
        return <EditorialTwo key={s.id} {...s.primary} />
      case "membership_table":
        return <MembershipTable key={s.id} {...s.primary} items={s.items} />
      case "contact_form":
        return <ContactBlock key={s.id} {...s.primary} />
      case "spacer":
        return <Spacer key={s.id} {...s.primary} />
      default:
        return null
    }
  })
  return <div className="slice-zone flex flex-column">{slice}</div>
}

export default SliceZone

SliceZone.propTypes = {
  slices: PropTypes.array.isRequired,
}
