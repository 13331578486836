import React, { useRef } from "react"

import useFormValidation from "./useFormValidation"

import { useSpring, animated, useChain, config } from "react-spring"

const INITIAL_STATE = {}

const MembershipForm = ({ onClose, formOpen, options }) => {
  const { handleSubmit, handleChange } = useFormValidation(INITIAL_STATE)
  const bgRef = useRef()
  const { x } = useSpring({
    ref: bgRef,
    x: formOpen ? 1 : 0,
    from: {
      x: 0,
    },
    config: config.stiff,
  })

  const formRef = useRef()
  const { y, z } = useSpring({
    ref: formRef,
    y: formOpen ? 0 : 10,
    z: formOpen ? 1 : 0,
    from: {
      y: 10,
      z: 0,
    },
    config: config.stiff,
  })
  useChain(formOpen ? [bgRef, formRef] : [formRef, bgRef])

  return (
    <animated.div
      className="w-100 h-100 fixed top-0 ph3 flex items-center bg-black-80 z-max"
      style={{
        opacity: x.interpolate(x => x),
        pointerEvents: formOpen ? "all" : "none",
      }}
    >
      <div className="w-100 h-100 fixed top-0 left-0 db" onClick={onClose} />
      <animated.div
        className="bg-white mw6 center w-100 ph4 pv5 ph5-l doc-bg"
        style={{
          opacity: z.interpolate(z => z),
          transform: y.interpolate(y => `translate3d(0,${y}%, 0)`),
        }}
      >
        <h3 className="f3 tracked ttu b tc mt0">Apply Now</h3>
        <form
          name="membership-form"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          subject="Thirty One Rows - Member Enquiry"
          action="/thank-you"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="membership-form" />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={handleChange} />
            </label>
          </p>
          <div className="mv3">
            <input
              type="text"
              name="name"
              className="w-100 input-reset pa3"
              placeholder="Full Name"
              required="true"
              onChange={handleChange}
            />
          </div>
          <div className="mv3">
            <input
              type="email"
              name="email"
              className="w-100 input-reset pa3"
              placeholder="Email Address"
              required="true"
              onChange={handleChange}
            />
          </div>
          <div className="relative select-wrapper">
            <select
              name="membership-type"
              className="w-100 pa3 input-reset bg-white"
              onChange={handleChange}
            >
              <option disabled selected value>
                Membership Type
              </option>
              {options.map(option => (
                <option value={option.title.text}>{option.title.text}</option>
              ))}
            </select>
          </div>
          <div className="mv3">
            <button
              type="submit"
              className="button-reset input-reset bg-white ph5 pv3 b tracked ttu w-100"
            >
              Submit
            </button>
          </div>
        </form>
      </animated.div>
    </animated.div>
  )
}

export default MembershipForm
