import React from "react"

const CenteredText = ({ heading, centered_text }) => {
  return (
    <section className="mv3 mv6-l ph4 ph6-l tc">
      <h2 className="f3 f1-l ttu b tracked measure center lh-title">
        {heading.text}
      </h2>
      <div
        dangerouslySetInnerHTML={{ __html: centered_text.html }}
        className="lh-copy measure-wide center"
      ></div>
    </section>
  )
}

export default CenteredText
