import React from "react"
import ReactPlayer from "react-player"
import { Link } from "gatsby"

const VideoBanner = ({ teaser, nav }) => {
  return (
    <section className="vw-100 vh-100 relative bg-black pointer overflow-hidden flex items-center">
      <ReactPlayer
        className="absolute top-0 left-0 vid_teaser o-40"
        url={teaser.url}
        playing
        muted={true}
        playsinline={true}
        width="100%"
        height="100%"
        loop={true}
      />
      <ul className="flex-l items-center justify-between w-100 ph3 ph6-l z-1 list">
        {nav.map(item => (
          <li>
            <Link
              to={`/${item.page.uid}`}
              className="link white f3 ttu tracked b pv3 pv0-l db tr tc-l pv0-l"
            >
              {item.nav_title.text}
            </Link>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default VideoBanner
