import React from "react"

const TwoColumnText = ({ heading, text }) => {
  return (
    <section className="mv3 mv6-l ph4 ph6-l mw9 center w-100">
      <h1 className="f-subheading-l f3 b ttu tracked measure-heading mb5-l lh-heading">
        {heading.text}
      </h1>
      <div
        dangerouslySetInnerHTML={{ __html: text.html }}
        className="lh-copy two-col"
      ></div>
    </section>
  )
}

export default TwoColumnText
