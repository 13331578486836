import React from "react"
import useFormValidation from "./useFormValidation"

const INITIAL_STATE = {}

const ContactForm = ({ onClose, formOpen }) => {
  const { handleSubmit, handleChange } = useFormValidation(INITIAL_STATE)

  return (
    <div className="mt6">
      <form
        name="contact-form"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/thank-you"
        className="mw8 center"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact-form" />
        <p hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <div className="flex-l flex-wrap justify-between w-100 ">
          <div className="w-100 w-50-l mv3 ph3-l">
            <input
              type="text"
              name="first-name"
              className="w-100 input-reset pa3"
              placeholder="First Name"
              required="true"
              onChange={handleChange}
            />
          </div>
          <div className="w-100 w-50-l mv3 ph3-l">
            <input
              type="text"
              name="last-name"
              className="w-100 input-reset pa3"
              placeholder="Last Name"
              required="true"
              onChange={handleChange}
            />
          </div>
          <div className="w-100 w-50-l mv3 ph3-l">
            <input
              type="email"
              name="email"
              className="w-100 input-reset pa3"
              placeholder="Email Address"
              required="true"
              onChange={handleChange}
            />
          </div>
          <div className="w-100 w-50-l mv3 ph3-l">
            <input
              type="text"
              name="phone"
              className="w-100 input-reset pa3"
              placeholder="Phone Number"
              required="true"
              onChange={handleChange}
            />
          </div>
          <div className="w-100 mv3 ph3-l">
            <textarea
              type="text"
              name="message"
              className="w-100 input-reset pa3"
              placeholder="Message"
              required="true"
              onChange={handleChange}
              rows="10"
            />
          </div>
        </div>
        <div className="mv3 mw5-l center">
          <button
            type="submit"
            className="button-reset input-reset bg-white ph5 pv4 b tracked ttu w-100 pointer w-100 w-auto-l"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  )
}

export default ContactForm
