import React from "react"
import Img from "gatsby-image"

const CenteredImage = ({ image, horizontal_wrap }) => {
  const wrap = horizontal_wrap === "true"
  return (
    <section className={`mv3 mv6-l ${wrap && "ph4 ph6-l"}`}>
      <Img fluid={image.localFile.childImageSharp.fluid} />
    </section>
  )
}

export default CenteredImage
