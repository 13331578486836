import React from "react"

const CenteredQuote = ({ centered_quote }) => {
  return (
    <section className="mv3 ph4 ph6-l">
      <h3 className="mw8 b center f3 f2-l lh-copy">{centered_quote.text}</h3>
    </section>
  )
}

export default CenteredQuote
