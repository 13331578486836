import React from "react"
import Img from "gatsby-image"

const EditorialTwo = ({ image_left, image_right, text_top, text_bottom }) => {
  return (
    <section className="mv3 mv6-l ph4 ph6-l w-100 mw9 center mag-two">
      <div className="right col">
        <div className="img">
          <Img fluid={image_right.localFile.childImageSharp.fluid} />
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: text_top.html }}
          className="text measure-wide f6 lh-copy pl4-l mv4 mt0-l mb5-l"
        />
      </div>
      <div className="left col">
        <div className="img">
          <Img fluid={image_left.localFile.childImageSharp.fluid} />
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: text_bottom.html }}
          className="text measure-wide f6 lh-copy mt4 mt5-l"
        />
      </div>
    </section>
  )
}

export default EditorialTwo
