import React from "react"
import Img from "gatsby-image"

const EditorialOne = ({ image_left, image_right, text, reverse_layout }) => {
  const reverse = reverse_layout === "true"
  return (
    <section
      className={`mv3 mv6-l ph4 ph6-l w-100 mw9 center mag-one ${reverse &&
        "reverse-layout"}`}
    >
      <div className="w-100 img-left">
        {reverse && (
          <div
            dangerouslySetInnerHTML={{ __html: text.html }}
            className={`lh-copy f6 measure-wide text dn db-l mb5 pl4-l`}
          />
        )}
        <Img fluid={image_left.localFile.childImageSharp.fluid} />
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: text.html }}
        className={`lh-copy f6 measure-wide text pl4-l mv4 mv0-l ${reverse &&
          "dn-l"}`}
      />
      <div className={`w-100 img-right ${reverse ? "mt5-l" : "mt6-l"}`}>
        <Img fluid={image_right.localFile.childImageSharp.fluid} />
      </div>
    </section>
  )
}

export default EditorialOne
