import { useState } from "react"
import { navigateTo } from "gatsby-link"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

function useFormValidation(initialState) {
  const [values, setValues] = useState(initialState)
  //--
  function handleChange(event) {
    event.persist()
    setValues(previousValues => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }))
  }
  //--
  function handleSubmit(event) {
    event.preventDefault()
    const form = event.target
    const formName = form.getAttribute("name").toString()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": `${formName}`,
        ...values,
      }),
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return { handleChange, handleSubmit, values }
}

export default useFormValidation
