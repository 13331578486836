import React from "react"
import { Link } from "gatsby"

const MembershipTeaser = ({ heading, text, page }) => {
  return (
    <section className="ph4 ph5-l mb4 mb6-l">
      <div className="h3 h4-l w1-px db bg-black center o-20"></div>
      <div className="mw8 center tc">
        <h3 className="f3 f1-l ttu b tracked mb0">{heading.text}</h3>
        <div
          dangerouslySetInnerHTML={{ __html: text.html }}
          className="lh-copy f6 measure-wide tc center"
        ></div>
        {page && (
          <Link
            to={`/${page.uid}`}
            className="link black ttu b tracked f4 mt4 db"
          >
            Apply Now
          </Link>
        )}
      </div>
    </section>
  )
}

export default MembershipTeaser
