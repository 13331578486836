import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const WineBlock = ({
  bottle_shot,
  background_image,
  heading,
  description,
  enquire_link,
  reverse_layout,
}) => {
  const reverse = reverse_layout === "true"
  return (
    <section className={`mv3 mv6-l wine-block ${reverse && "reverse-layout"}`}>
      <div className="ph4 pb6-l img-wrap relative">
        <div className="bs z-2">
          <Img
            fluid={bottle_shot.localFile.childImageSharp.fluid}
            className="bs z-2"
          />
        </div>
        <div className="bg z-1">
          <Img
            fluid={background_image.localFile.childImageSharp.fluid}
            className="bg z-1"
          />
        </div>
        <div className="absolute bottom-0 left-0 h-50 w-100 bg-white db dn-l" />
      </div>
      <div className="text-outer w-100 pt6-l flex-l">
        <div className="bg-white ph4 ph5-l pt4 pb5 pv6-l w-100 text-wrap flex-l flex-column-l justify-center tl">
          <h2 className="f3 f1-l ttu tracked b mb3">{heading.text}</h2>
          <div
            dangerouslySetInnerHTML={{ __html: description.html }}
            className="lh-copy f6 measure-wide"
          ></div>
          {enquire_link && (
            <Link
              to={`/${enquire_link.uid}`}
              className="link black ttu b tracked mt2 f5"
            >
              Enquire Now
            </Link>
          )}
        </div>
      </div>
    </section>
  )
}

export default WineBlock
