import React from "react"

import ContactForm from "../forms/contactForm"

const ContactBlock = ({ heading, text }) => {
  return (
    <section className="mv3 mv6-l ph4 ph6-l">
      <h2 className="ttu b tracked tc f2 f1-l">{heading.text}</h2>
      <div
        dangerouslySetInnerHTML={{ __html: text.html }}
        className="center measure-widest tc lh-copy f5"
      />
      <ContactForm />
    </section>
  )
}

export default ContactBlock
