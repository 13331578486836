import React, { useState } from "react"

import MembershipForm from "../forms/membershipForm"

const MembershipTable = ({ heading, items }) => {
  const [formOpen, setFormOpen] = useState(false)
  return (
    <>
      <section className="mv3 mv6-l ph4 ph6-l">
        <h1 className="ttu f2 f1-l b tracked measure center tc">
          {heading.text}
        </h1>
        <div className="flex-l mw9 center mv6">
          {items.map(item => (
            <div className="w-100 w-50-l tc ph5-l">
              <h3 className="b f2">{item.title.text}</h3>
              <div
                dangerouslySetInnerHTML={{ __html: item.text.html }}
                className="lh-copy measure center f6"
              />
              <div className="b f4 mt5">{item.price.text}</div>
            </div>
          ))}
        </div>
        <button
          className="center ph6 b pv4 input-reset button-reset flex pointer ttu tracked"
          onClick={() => setFormOpen(true)}
        >
          Apply Now
        </button>
      </section>
      <MembershipForm
        onClose={() => setFormOpen(false)}
        formOpen={formOpen}
        options={items}
      />
    </>
  )
}

export default MembershipTable
